.tickets_table td{
    font: normal normal normal 14px "Segoe UI" !important;
    letter-spacing: 0px;
    color: #0D4A61 !important;
}

.tickets_table th{
    font: normal normal bold  14px "Segoe UI" !important;
    
    letter-spacing: 0px;
    color: #0D4A61 !important;
}