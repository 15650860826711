.confirm-btns{
    padding: 4px 15px;
    background-color: #0D4A61;
    font-family: "Segoe UI";
    color: white;
    border: none;
    outline: none;
    cursor: pointer !important;
    border-radius: 7px;
}

.confirm-btns:focus{
    outline: none;
    border: none;
}

.modal-content{
    margin-left: 5rem;
    margin-right: 5rem;
}

.card-body > p{
    font-weight: 700;
    padding-bottom: 1rem;
   
}

.confirm-btns-container{
    float: right;
    padding-right: 1rem;
}

.accordion-button{
    font-size: 14px;
    font-weight: 500;
}

.delivery_address_items{
    width: 100%;
    border: 1px solid #0D4A61;
    border-radius: 7px;
}

.delivery_address_items fieldset{
   border: none !important;
}

.delivery_address_items > div > label > input{
    display: flex;
    flex: 0.02;
    padding-top: 10px;
}

.delivery_address_items > h2{
    display: flex;
    flex: 0.95;
}

.MuiFormHelperText-root{
    color: red !important;
}

.accordion-header{
    border-bottom: 1px solid rgb(167, 164, 164);
}


.MuiTypography-body1 {
    font-size: 0.9rem !important;
    color: #696969 !important;
    font-family: "Segoe UI";
    font-weight: 500 !important;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}
.MuiPaper-root{
    background-color: transparent !important;
}


.helper_text{
    color: #FF0000 !important;
    font: normal normal normal 13px "Segoe UI";
  }

.profile_form .ant-row{
    display: flex !important;
    flex-flow: column wrap;
    align-items: flex-start;
}

.profile_form .ant-input{
    min-width: 220px !important;
    border: 1px solid #0D4A61;
    border-radius: 7px;
    font-weight: 400 !important;
    font: normal normal normal 14px "Segoe UI";
    letter-spacing: 0px;
   
    
}

.profile_form .ant-input[disabled]{
    background-color: #F3F6FB !important;
    color:  #5D5D5D !important;
    border: none;
    outline: none;
}

.profile_form .ant-input:focus{
    outline: #0D4A61 !important;
   
}


.profile_form .ant-input:hover{
    
    outline: #0D4A61 !important;
}

.profile_form .ant-form-item-label > label{
    font: normal normal normal 16px "Segoe UI";
    font-weight: 500 !important;
    letter-spacing: 0px;
    color: #5D5D5D;
    text-transform: capitalize;
}

.delivery_address_items .MuiFormLabel-root{
    font: normal normal bold 16px 'Segoe UI' !important;
    letter-spacing: 0px !important;
    font-weight: 700 !important;
    letter-spacing: 0px !important;
    color: #0D4A61 !important;
    text-transform: capitalize !important;
}

.profile_msg_preference .MuiCheckbox-colorPrimary.Mui-checked{
    color: #00F729 !important;
}

.profile_update_btn{
    background: #FDC32C 0% 0% no-repeat padding-box;
    border-radius: 7px;
    font: normal normal 600 14px "Segoe UI";
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    padding: 10px;
    border: none !important;
    cursor: pointer;
    outline: none !important;
}

.profile_update_btn:focus{
    outline: none !important;
    border: none !important;
    cursor: pointer;
}

.profile_pwd_btn{
    margin-right: 20rem !important;
    background: #FDC32C 0% 0% no-repeat padding-box;
    border-radius: 7px;
    font: normal normal 600 14px "Segoe UI";
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    padding: 10px;
    border: none !important;
    outline: none !important;
    cursor: pointer;
}

.profile_pwd_btn:focus{
    outline: none !important;
    border: none !important;
    cursor: pointer;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.ab_btn{
    border: 1px solid #0D4A61;
    border-radius: 7px;
    font: normal normal 600 14px "Segoe UI";
    letter-spacing: 0px;
    color: #0D4A61;
    padding: 0 1rem 0 0.8rem;
    background-color: #FFFFFF;
    outline: none;
    cursor: pointer;
}

.ab_btn:hover{
    outline: none !important;
    cursor: pointer;
    background-color: #0D4A61;
    color:#FFFFFF;
}

.ab_btn:focus{
    outline: none !important;
    cursor: pointer;
}

.address_input .MuiInputBase-input{
    min-width: 350px !important;
    font-size: 14px !important;
    font-family: "Segoe UI" !important;
    letter-spacing: 0px;
    color: #5D5D5D;
}

.MuiInputBase-inputMarginDense {
    padding-top: 7px;
}
