.progress {
    height: 0.5rem !important;
}

.progress-bar{
    background-color: #08576f !important;
}

.order-font{
    font-weight: 700 !important;
}

.progress-color{
    color: #08576f !important;
    font-weight: 700 !important;
}

.viewOrder_table td{
    padding: 0.15rem !important;
    font: normal normal normal 14px "Segoe UI" !important;
    letter-spacing: 0px !important;
    color: #0D4A61 !important;
    
}

.viewOrdermain_table td{
    padding: 0.15rem !important;
    font: normal normal normal 14px "Segoe UI" !important;
    letter-spacing: 0px !important;
    color: #0D4A61 !important;
    max-width: 30px !important;
}

.viewOrdermain_table th{
    font: normal normal bold  14px "Segoe UI" !important;
    padding: 0.35rem !important;
    letter-spacing: 0px;
    color: #0D4A61 !important;
}

.viewOrder_table th{
    font: normal normal bold  14px "Segoe UI" !important;
    padding: 0.35rem !important;
    letter-spacing: 0px;
    color: #0D4A61 !important;
}

