.hero {
    min-height: 100vh;
    width: 100%;
    /* background-image: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),url(../../images/yantralive-new-banner2.jpg);
    background-position: center;
    background-size: cover;
    position: absolute; */
}

.hero1 {
    min-height: 100vh;
    width: 100%;
    background-image: url(../../images/yantralive-new-banner3.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    overflow: hidden;
   
}

.img_div {
    max-width:50%;
}

.img_banner1{
    max-width:50%;
    min-height: 100vh;
    max-height: 100vh;
    object-fit: cover;
    background-repeat: no-repeat;
    width: 100%;
    background:  linear-gradient(to bottom left, #000000 -1%, #000000 100% ) !important; 
}

.form-box {
    width: 50%;
    height: 100vh;
    position: relative;
    background: #F3F6FB;
    padding: 5px;
    overflow: hidden;  
}

.form-box .logo{
    text-align: center;  
    background: white; 
    border-radius: 100%;
    box-shadow: 0px 0px 6px #00000029;
    margin:4% 43% 2% 43%;
}

.form-box1 {
    width: 50%;
    height: 750px;
    position: relative;
    background: #F3F6FB;
    padding: 5px;
    overflow: hidden;  
}

.welcome-text{
    text-align: center;
    font: normal normal "Sitka" Small;
    font-size: 24px !important;
    font-weight: 700;
    letter-spacing: 0px;
    color: #0D4A61;
}

.jss-text{
    text-align: center;
    font: normal normal 14px Sitka Small;
    letter-spacing: "NaNpx";
    color: #0D4A61;
    font-weight: 800;
    padding-top: 0.3rem;
}

.button-box {
    width: 225px;
    margin: 18px auto;
    position: relative;
    box-shadow: 0 0 20px 9px #ff61241f;
    border-radius: 7px;
    background: #fdc32c;
}

.toggle_btn {
    padding: 6px 30px;
    cursor: pointer;
    background: transparent;
    border: 0;
    color: white;
    outline: none;
    position: relative;
    font: normal normal 14px "Sitka" Small;
    font-weight: 600;
    letter-spacing: 0px;
}

.toggle_btn1 {
    padding: 10px 30px;
    cursor: pointer;
    background: transparent;
    border: 0;
    color: #004960;
    outline: none;
    position: relative;
    font: normal normal bolder 14px Sitka Small;
    letter-spacing: 0px;
    color: #0D4A61;
    margin-left: 12rem;
}



.toggle_btn1:hover {
    padding: 10px 30px;
    cursor: pointer;
    background: transparent;
    border: 0;
    color: #004960;
    outline: none;
    position: relative;
    text-decoration: underline;
}

.forgot__Pwd{
    cursor: pointer;
    background: transparent;
    border: 0;
    color: #004960;
    outline: none;
    position: relative;
    text-align: left !important;
 
    font: normal normal bolder 14px Sitka Small;
    letter-spacing: 0px;
    color: #0D4A61;
}

.forgot__Pwd:hover {
    cursor: pointer;
    background: transparent;
    border: 0;
    color: #004960;
    outline: none;
    position: relative;
    text-decoration: underline;
}


.bttn {
    top: 0;
    position: absolute;
    width: 110px;
    height: 100%;
    background: #004960;
    border-radius: 7px;
    transition: .5s;
}

.input-group {
    margin:0 auto;
    position: relative;
    text-align: center !important;
    transition: .5s;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.input-group .ant-form-item-control-input-content{
    text-align: center !important;
    min-width: 20rem;
}

.input-group .ant-form-item-explain div{
    text-align: left !important;
    padding-left: 7rem;
    padding-bottom: 0.5rem;
}

.input-field {
    width: 100% !important;
    padding-left: 14px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    margin: 0px 0 !important;
    outline: none !important;
    background: transparent !important;
    border: 1px solid #0D4A61 !important;
    border-radius: 7px !important;
    text-align: left !important;
    font-weight: 600;
}



.input-field:focus {
    box-shadow: none !important;
    outline: none !important;
    background: transparent !important;
    border: 1px solid #0D4A61;
    border-radius: 7px;
}

.input-field:hover {
    box-shadow: none !important;
    outline: none !important;
    background: transparent !important;
    border: 1px solid #0D4A61;
    border-radius: 7px;
}


.submit-btn {
    background: #0D4A61 0% 0% no-repeat padding-box;
    border: 1px solid #0D4A61;
    border-radius: 10px;
    font: normal normal normal 16px Sitka Small;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-align: center;
    padding: 8px 35px;
    cursor: pointer;
    display: block;
    float: right;
    outline: none !important;
    margin-right: 11rem;
    
}



.submit-btn:focus {
    border: 0 !important;
    outline: none !important;
}


#login {
    margin: 0 auto !important;
    width: 100%;
    display: flex;
    align-content: center !important;
    justify-content: center !important;
    text-align: center !important;
}
#login1 {
    left: -400px;
}

#register {
    left: 100%;
}
#register1 {
    left: 50px;
}

#btn {
    
}
#btn1 {
    left: 115px;
}

.social-icons {
    margin: 10px auto;
    text-align: center;
}

.social-icons span {
    width: 65px;
    /* height: 40px; */
    padding: 5px 0;
    margin: 0 10px;
    
    cursor: pointer;
   
    display: inline-block;
    text-align: center;
}


.content-group {
    top: 520px;
    left: -95px;
    position: absolute;
    width: 100%;
    transition: .5s;
}