.dashboard{
    background:'#eeeeee'
}

.dashboard_th{
   
    font: normal normal bold 20px Segoe UI;
    letter-spacing: 0px;
    color: #5D5D5D;
    font-size: 14px;
    
}